import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPlanPrice, getTranslatedDescription, getTranslatedName } from '../../Utilities/ProductUtilities';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { Image } from '../Image/Image';
import styles from './ShoppingProductCard.module.scss';
import { Title } from '../Title/Title';
import { AuthContext, DesignContext, OrderContext, ShoppingCartContext } from '../../Contexts';
import { PlanPriceCard } from '../PlanPrice/PlanPriceCard';
import cx from 'classnames';
import { Badge, Radio } from 'antd';
import { PageProduct } from '../../Pages/Product';
import { Product } from '../../interfaces';
import { getOrderCurrency } from '../../Utilities/OrderUtilities';

type ShoppingProductCardProps = {
  product: Product;
  productIndex: number;
};

export function ShoppingProductCard({ product, productIndex }: ShoppingProductCardProps) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const name = getTranslatedName(product, i18n.language);
  const [showProductModal, setShowProductModal] = useState(false);
  const [productId, setProductId] = useState('');
  const { order } = useContext(OrderContext);
  const { globals } = useContext(DesignContext);
  const { enable_details } = useContext(ShoppingCartContext);

  const checkIfTrailApplies = () => {
    if (product.type == 'BASE' && product.settings.trial_days > 0) {
      return true;
    } else {
      if (order.products != undefined && order.products.find((p) => p.product.type == 'BASE') != undefined) {
        var test = order.products.find((p) => p.product.type == 'BASE').product.settings.trial_days;
        var trial = product.settings.trial_days;
        if (test == trial && trial > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  if (checkIfTrailApplies()) {
    return (
      <div
        className={styles.component}
        style={{
          border: '2px solid #dedede',
          padding: 10,
          borderRadius: 5,
          backgroundColor: '#fff',
          paddingTop: 30,
        }}
      >
        {showProductModal && <PageProduct id={productId} onCancel={() => setShowProductModal(false)}></PageProduct>}
        <Badge.Ribbon placement="end" text={t('try') + ' ' + product.settings.trial_days + ' ' + t('daysforfree')} color={'green'} style={{ marginTop: 30 }}>
          <div style={{ fontSize: 24, marginBottom: 20, fontFamily: globals['font-family'] }}>{name}</div>
        </Badge.Ribbon>
        <div
          onClick={() => {
            setProductId(product._id);
            setShowProductModal(true);
          }}
          className={styles.imageContainer}
          style={{ cursor: 'pointer' }}
        >
          <Image layoutClass={styles.image} src={product.external.images[0]?.url} alt={name} />
        </div>
        {(enable_details === undefined || enable_details) && (
          <div
            onClick={() => {
              setProductId(product._id);
              setShowProductModal(true);
            }}
            style={{
              cursor: 'pointer',

              height: 45,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              borderRadius: 5,
              borderWidth: 2,
              marginBottom: 3,
              border: '2px solid #dedede',
              fontSize: 14,
              textAlign: 'center',
              marginTop: 3,
            }}
          >
            <div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', height: 45, alignContent: 'center' }}>{t('product_detials_channels')}</div>
          </div>
        )}
        {getTranslatedDescription(product, i18n.language) != undefined && <div style={{ fontSize: 14, padding: 20, backgroundColor: '#f2f2f2', height: '100%' }}>{getTranslatedDescription(product, i18n.language)}</div>}
        <div className={styles.addToCart} style={{ fontSize: 14 }}>
          <AddProductToCart
            {...{
              product,
              productIndex,
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={styles.component}
        style={{
          border: '2px solid #dedede',
          padding: 10,
          borderRadius: 5,
          backgroundColor: '#fff',
          paddingTop: 30,
        }}
      >
        {showProductModal && <PageProduct id={productId} onCancel={() => setShowProductModal(false)}></PageProduct>}

        <div style={{ fontSize: 24, marginBottom: 20 }}>{name}</div>
        <div
          onClick={() => {
            setProductId(product._id);
            setShowProductModal(true);
          }}
          className={styles.imageContainer}
          style={{ cursor: 'pointer' }}
        >
          <Image layoutClass={styles.image} src={product.external.images[0]?.url} alt={name} />
        </div>
        <div
          onClick={() => {
            setProductId(product._id);
            setShowProductModal(true);
          }}
          style={{
            cursor: 'pointer',

            height: 45,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            borderWidth: 2,
            marginBottom: 3,
            border: '2px solid #dedede',
            fontSize: 14,
            textAlign: 'center',
            marginTop: 3,
          }}
        >
          <div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', height: 45, alignContent: 'center' }}>{t('product_detials_channels')}</div>
        </div>
        {getTranslatedDescription(product, i18n.language) != undefined && <div style={{ fontSize: 14, padding: 20, backgroundColor: '#f2f2f2', height: '100%' }}>{getTranslatedDescription(product, i18n.language)}</div>}
        <div className={styles.addToCart} style={{ fontSize: 14 }}>
          <AddProductToCart
            {...{
              product,
              productIndex,
            }}
          />
        </div>
      </div>
    );
  }
}

type AddBaseProductToCardProps = {
  product: Product;
  productIndex: number;
  layoutClass?: string;
  disabled?: boolean;
  handleNext?: Function;
};

function AddProductToCart({ product, productIndex, layoutClass }: AddBaseProductToCardProps) {
  const { baseProducts, shopSelectBaseProduct, shopSelectExtraProduct } = useContext(OrderContext);
  const { order } = useContext(OrderContext);
  const { customer } = useContext(AuthContext);
  const { globals } = useContext(DesignContext);

  const isCompatibleWithBasePlan = (product, plan) => {
    if (product.type === 'BASE') {
      return true;
    }

    const base = baseProducts.find((x) => x.plans.plans.some((p) => p.isSelected));

    if (!base) {
      return false;
    }

    const basePlan = base.plans.plans.find((p) => p.isSelected);

    return plan.duration_period_in === basePlan.duration_period_in && plan.duration === basePlan.duration;
  };

  return (
    <div
      className={cx(styles.component, {
        [`${layoutClass}`]: layoutClass,
      })}
    >
      {product.plans?.plans
        .filter((plan) => isCompatibleWithBasePlan(product, plan))
        .map((plan, index) => {
          const currency = getOrderCurrency({ ...order, customer });
          const price = getPlanPrice(plan, currency);
          return (
            <>
              {!isNaN(price) && (
                <div
                  key={index}
                  className={cx({
                    [`${styles.selectedPlan}`]: plan.isSelected,
                  })}
                  style={{
                    fontFamily: globals['font-family'],
                    height: 45,
                    alignContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    marginBottom: 3,
                    paddingLeft: 0,
                    border: '2px solid #dedede',
                    fontSize: 14,
                  }}
                >
                  <div style={{ marginLeft: 20 }}>
                    <Radio name={`${product._id}_plan_${plan._id}_${index}`} checked={plan.isSelected} onChange={() => (product.type === 'BASE' ? shopSelectBaseProduct(product, plan) : shopSelectExtraProduct(product, plan))}>
                      <PlanPriceCard {...{ plan, product }} />
                    </Radio>
                  </div>
                </div>
              )}
            </>
          );
        })}
    </div>
  );
}
